import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  useRef,
} from "react";

import liff from "@line/liff";
import liffHelper from "../../Utils/liffHelper";

// ref:https://github.com/line/line-liff-v2-starter
// ref:https://github.com/BensonLiao/liff-react-boilerplate

import { Button } from "antd";
import "./BusinessCard.scss";

const myLiffId = `${process.env.REACT_APP_LIFF_ID}`;

export default function BusinessCard() {
  const shareTarget = async () => {
    console.log("share targe ");
    await liffHelper.getUserProfile();
    console.log("share 0");
    await liff.shareTargetPicker([
      {
        type: "flex",
        altText: "您好 我是群創能源的 許峻瑋",
        contents: {
          type: "carousel",
          contents: [
            {
              type: "bubble",
              header: {
                type: "box",
                layout: "horizontal",
                contents: [
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "image",
                        url: "https://storage.googleapis.com/prod-cirsolar-schedule-web/Allen-%E5%A4%A7%E9%A0%AD%E7%85%A7.jpg",
                      },
                    ],
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "Allen",
                        size: "xxl",
                        weight: "bold",
                        decoration: "none",
                        color: "#273A8A",
                      },
                      {
                        type: "text",
                        text: "許峻瑋",
                        weight: "bold",
                        contents: [
                          {
                            type: "span",
                            text: "許峻瑋",
                            size: "lg",
                          },
                          {
                            type: "span",
                            text: "    總經理",
                            size: "sm",
                          },
                        ],
                      },
                    ],
                    alignItems: "flex-start",
                    justifyContent: "center",
                  },
                ],
              },
              body: {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        contents: [
                          {
                            type: "span",
                            text: "公司 l ",
                            color: "#939393",
                            size: "md",
                          },
                          {
                            type: "span",
                            text: "群創能源科技股份有限公司",
                          },
                        ],
                        action: {
                          type: "uri",
                          label: "action",
                          uri: "https://cirsolar.tw/",
                        },
                      },
                    ],
                    margin: "10px",
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "hello, world",
                        contents: [
                          {
                            type: "span",
                            text: "手機 l ",
                            color: "#939393",
                          },
                          {
                            type: "span",
                            text: "0918-550-777",
                          },
                        ],
                      },
                    ],
                    margin: "md",
                    action: {
                      type: "uri",
                      label: "0918550777",
                      uri: "tel:0918550777",
                    },
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "hello, world",
                        contents: [
                          {
                            type: "span",
                            text: "Mail l ",
                            color: "#939393",
                          },
                          {
                            type: "span",
                            text: "allen@cirsolar.com.tw",
                          },
                        ],
                      },
                    ],
                    margin: "md",
                    action: {
                      type: "uri",
                      label: "allen@cirsolar.com.tw",
                      uri: "mailto:allen@cirsolar.com.tw",
                    },
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "hello, world",
                        contents: [
                          {
                            type: "span",
                            text: "統編 l ",
                            color: "#939393",
                          },
                          {
                            type: "span",
                            text: "82876042",
                          },
                        ],
                      },
                    ],
                    margin: "md",
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "地址 l ",
                        contents: [],
                        action: {
                          type: "uri",
                          label: "action",
                          uri: "https://goo.gl/maps/tTqmtFXbmWs6piT56",
                        },
                        color: "#939393",
                      },
                      {
                        type: "text",
                        text: "613  嘉義縣朴子市平和路11-6號3樓",
                        action: {
                          type: "uri",
                          label: "action",
                          uri: "https://goo.gl/maps/tTqmtFXbmWs6piT56",
                        },
                      },
                    ],
                    margin: "md",
                  },
                ],
              },
              footer: {
                type: "box",
                layout: "horizontal",
                contents: [
                  {
                    type: "image",
                    url: "https://storage.googleapis.com/prod-cirsolar-schedule-web/LOGO_20221130.png",
                    size: "35px",
                    flex: 2,
                    align: "center",
                    gravity: "center",
                    offsetStart: "5px",
                    offsetBottom: "5px",
                  },
                  {
                    type: "text",
                    text: "群創能源 l 堅持初心 創造友善生活",
                    flex: 8,
                    position: "relative",
                    size: "xs",
                    color: "#727272",
                    weight: "bold",
                  },
                ],
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#D7EEFF",
              },
              styles: {
                body: {
                  backgroundColor: "#f5f5f5",
                },
              },
            },
            {
              type: "bubble",
              hero: {
                type: "image",
                size: "full",
                aspectRatio: "20:13",
                aspectMode: "cover",
                url: "https://storage.googleapis.com/prod-cirsolar-schedule-web/0001_20221130%7F%7F%7F%7F%7F%7F%7F%7F.jpg",
              },
              body: {
                type: "box",
                layout: "vertical",
                spacing: "sm",
                contents: [
                  {
                    type: "text",
                    text: "太陽能屋頂型專家",
                    wrap: true,
                    weight: "bold",
                    size: "xl",
                  },
                  {
                    type: "box",
                    layout: "baseline",
                    contents: [
                      {
                        type: "text",
                        text: "聚焦成就專業",
                        wrap: true,
                        weight: "bold",
                        size: "md",
                        flex: 0,
                      },
                    ],
                  },
                  {
                    type: "box",
                    layout: "horizontal",
                    contents: [],
                  },
                ],
                borderColor: "#273A8A",
              },
              footer: {
                type: "box",
                layout: "vertical",
                spacing: "sm",
                contents: [
                  {
                    type: "button",
                    style: "primary",
                    action: {
                      type: "uri",
                      label: "群創能源是一家什麼樣的公司?",
                      uri: "https://www.youtube.com/watch?v=ddycb9mhccg",
                    },
                    color: "#273A8A",
                  },
                  {
                    type: "button",
                    action: {
                      type: "uri",
                      label: "百大案場實績",
                      uri: "https://cirsolar.tw/front/success-case/success-case-index",
                    },
                  },
                ],
              },
            },
            {
              type: "bubble",
              hero: {
                type: "image",
                size: "full",
                aspectRatio: "20:13",
                aspectMode: "cover",
                url: "https://storage.googleapis.com/prod-cirsolar-schedule-web/0002_20221130%7F%7F%7F%7F%7F%7F%7F%7F%7F%7F%7F%7F.JPG",
              },
              body: {
                type: "box",
                layout: "vertical",
                spacing: "sm",
                contents: [
                  {
                    type: "text",
                    text: "暖心公益案場",
                    wrap: true,
                    weight: "bold",
                    size: "xl",
                  },
                  {
                    type: "box",
                    layout: "baseline",
                    flex: 1,
                    contents: [
                      {
                        type: "text",
                        text: "溫暖傳遞 價值永續",
                        wrap: true,
                        weight: "bold",
                        size: "md",
                        flex: 0,
                      },
                      {
                        type: "text",
                        text: " ",
                        wrap: true,
                        weight: "bold",
                        size: "sm",
                        flex: 0,
                      },
                    ],
                  },
                ],
              },
              footer: {
                type: "box",
                layout: "vertical",
                spacing: "sm",
                contents: [
                  {
                    type: "button",
                    flex: 2,
                    style: "primary",
                    color: "#F7D546",
                    action: {
                      type: "uri",
                      label: "太陽能創造無限可能",
                      uri: "https://www.youtube.com/watch?v=0eU9Yi_FyFw",
                    },
                  },
                  {
                    type: "button",
                    action: {
                      type: "uri",
                      label: "加入公益行列",
                      uri: "https://cirsolar.tw/front/home/public-welfare",
                    },
                    color: "#F7D546",
                  },
                ],
              },
            },
          ],
        },
      },
    ]);

    console.log("share 1");
  };

  return (
    <div className="business-card-comp">
      <div className="btn-area">
        <div className="line-brn" onClick={() => shareTarget()}>
          分享名片
        </div>
      </div>
    </div>
  );
}
